import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home page of ybzconsulting.io" />
    <h1>Welcome to ybzconsulting.io</h1>
      <p>This site is under construction. please have another look in the future.</p>
      <p>ybzconsulting is registered in 's-Hertogenbosch and aims to deliver IT consultancy, Interim managment capacity and Blockchain & Distributed Ledger services.</p>
  </Layout>
)

export default IndexPage
